import React, { Component } from "react";
import { Link } from "gatsby";

export default class Projects extends Component {
  render() {
    const { projectsData } = this.props;

    return (
      <div>
        <div className="site-container Projects-page" id="Projects">
          <div className="container">
            <div className="section-head">
            </div>
            <ul
              className={`Projects-list ${projectsData?.length < 5 ? "few-Projects" : ""
                }`}
            >
              {projectsData?.map((project, index) => {
                const projectID = project.id
                return (
                  <li key={index} className="item">
                    <div className="inner" >
                      <Link className="link" to={`/Project/?id=${projectID}`} />
                      <img src={project.featuredImg} alt="featured rendering" style={{ objectFit: 'fill', objectPosition: '50% 50%' }} />
                      <div className="details" >
                        <h3 className="title">{project.title}</h3>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div >
    );
  }
}
